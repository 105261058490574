import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import AppContext from 'context/Context';
import {
  firstProTier,
  goldColor,
  secondProTier,
  traderProTier,
  isTraderCompleted
} from 'staticData/common';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { monthsList } from 'staticData/languages';
import { Account, Billings as textLang } from 'staticData/languages';
import TraderModal from 'pages/common/modals/TraderModal';
import { trackEvent } from 'services/externals/mixpanel';
import PricingModal from 'pages/Pricing/modals';
import { isInTestAB, portfolioTest } from 'services/testAB/functions';

const Subscription = ({ getInfo }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const routerHistory = useHistory();
  const [traderModalShow, setTraderModalShow] = useState(false);
  // get params in url
  const [showModalPricing, setShowModalPricing] = useState(false);
  const [upgradePricePreview, setUpgradePricePreview] = useState();

  const manageSubscription = async (type = null) => {
    const resp = await endpointInterface(
      lang,
      'backend',
      'manageUserSubscription',
      'post',
      true
    );
    if (resp.responseStatus !== 200) {
      // TODO: Manage backend error, show a popup or something?
      console.error('Error occurred while managing subscription');
      return;
    }
    trackEvent('subscription button', {
      event: type
    });
    window.location.href = resp.data.redirect_url;
  };

  const getUpgradePreview = async () => {
    const resp = await endpointInterfaceV2({
      internalEndpoint: 'upgradePreview',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: {
        tier: secondProTier,
        months: userInfos.subscription.recurring_months
      }
    });
    if (resp.validResponse) setUpgradePricePreview(resp.data.amount_due);
  };

  const disableSubsButton = !(
    userInfos.subscription &&
    Object.keys(userInfos.subscription).length > 0 &&
    userInfos.subscription.management_allowed
  );

  const userDate = userInfos.subscription
    ? new Date(userInfos.subscription.start_date * 1000)
    : null;

  const createdAt =
    userInfos.subscription && Object.keys(userInfos.subscription).length
      ? lang === 'it-IT'
        ? String(userDate.getDate()) +
          ' ' +
          monthsList.months[lang][userDate.getMonth()] +
          ' ' +
          String(userDate.getFullYear())
        : monthsList.months[lang][userDate.getMonth()] +
          ' ' +
          userDate.getDate() +
          ' ' +
          userDate.getFullYear()
      : null;

  const subType = userInfos.subscription
    ? userInfos.subscription.recurring_months === 1
      ? textLang.monthly[lang]
      : userInfos.subscription.recurring_months === 12
      ? textLang.yearly[lang]
      : null
    : null;

  useEffect(() => {
    // get upgrade price preview
    if (
      userInfos.pro_tier === firstProTier &&
      !userInfos.subscription?.trialing
    )
      getUpgradePreview();
  }, []);

  return (
    <>
      <TraderModal
        showModal={traderModalShow}
        setShowModal={setTraderModalShow}
        getInfo={getInfo}
      />
      {showModalPricing && (
        <PricingModal
          getInfo={() => null}
          showModal={showModalPricing}
          setShowModal={setShowModalPricing}
          whatToDo={userInfos?.pro_tier === 0 ? 'newSub' : 'upgrade'}
          newProTier={userInfos?.pro_tier === 0 ? 0 : 2}
          type="account"
        />
      )}
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header>
              <h4>Dettagli Abbonamento</h4>
            </Card.Header>
            <Card.Body>
              {userInfos.subscription &&
              Object.keys(userInfos.subscription).length > 0 ? (
                <>
                  <Row>
                    <Col>
                      <h3>
                        {userInfos.pro_tier >= traderProTier ? (
                          <span className="text-success">
                            <span>Private</span>
                          </span>
                        ) : userInfos.pro_tier === secondProTier ? (
                          <span style={{ color: goldColor }}>
                            <span>Investor</span>
                          </span>
                        ) : (
                          userInfos.pro_tier === firstProTier && (
                            <span style={{ color: goldColor }}>
                              <span>Explorer</span>
                            </span>
                          )
                        )}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col lg={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    {subType && (
                      <Col>
                        <Row>
                          <Col as={Flex} xs={12}>
                            <FontAwesomeIcon icon="wallet" className="me-2" />
                            <h6>{textLang.subType[lang]}</h6>
                          </Col>
                          <Col
                            as={Flex}
                            xs={12}
                            lg={12}
                            justifyContent="between"
                            className="mt-2"
                          >
                            <h5>
                              {subType}
                              {userInfos.subscription.trialing
                                ? ` (${textLang.trial[lang]})`
                                : ''}
                            </h5>
                          </Col>
                        </Row>
                        <Row className="my-1 d-sm-none">
                          <Col lg={12}>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {createdAt && (
                      <Col>
                        <Row>
                          <Col as={Flex} xs={12}>
                            <FontAwesomeIcon icon="calendar" className="me-2" />
                            <h6>{textLang.subsFrom[lang]}</h6>
                          </Col>
                          <Col
                            as={Flex}
                            xs={12}
                            lg={12}
                            justifyContent="between"
                            className="mt-2"
                          >
                            <h5>{createdAt}</h5>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {(subType || createdAt) && (
                    <Row className="my-1">
                      <Col lg={12}>
                        <hr />
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-3">
                    <Col>
                      <h4>Gestisci Abbonamento</h4>
                    </Col>
                  </Row>
                  <Row className="align-items-center text-center mb-3">
                    <Col xs={12} className="py-2 border-bottom">
                      <div className="d-block d-sm-flex justify-content-between align-items-center">
                        <span className="text-start">
                          <p className="mb-0 text-dark">
                            {textLang.subDetails[lang]}
                          </p>
                        </span>
                        <span className="d-flex justify-content-center mt-2 mt-sm-0">
                          <Button
                            variant="falcon-primary"
                            className="d-flex d-sm-block align-items-center"
                            onClick={() => manageSubscription('view')}
                            disabled={disableSubsButton}
                          >
                            {textLang.subDetailsButton[lang]}
                            <FontAwesomeIcon
                              className="ms-2"
                              icon="external-link-alt"
                            />
                          </Button>
                        </span>
                      </div>
                    </Col>
                    <Col xs={12} className="py-2 border-bottom">
                      <div className="d-block d-sm-flex justify-content-between align-items-center">
                        <span className="text-start">
                          <p className="mb-0 text-dark">
                            {textLang.updateMethod[lang]}
                          </p>
                        </span>
                        <span className="d-flex justify-content-center mt-2 mt-sm-0">
                          <Button
                            variant="falcon-primary"
                            className="d-flex d-sm-block align-items-center"
                            onClick={() =>
                              manageSubscription('update payment method')
                            }
                            disabled={disableSubsButton}
                          >
                            {textLang.updateButtton[lang]}
                            <FontAwesomeIcon
                              className="ms-2"
                              icon="external-link-alt"
                            />
                          </Button>
                        </span>
                      </div>
                    </Col>
                    {userInfos.pro_tier === firstProTier ? (
                      <Col xs={12} className="py-2 border-bottom">
                        <div className="d-block d-sm-flex justify-content-between align-items-center">
                          <span className="text-start">
                            <p className="mb-0 text-dark">
                              {textLang.investorUpgrade[lang]}
                              {upgradePricePreview != null && (
                                <span>
                                  {textLang.for[lang]}
                                  <b>€{upgradePricePreview.toFixed(2)}</b>
                                  {process.env.REACT_APP_ENV === 'DEV' &&
                                    ' \u{1F525}'}
                                </span>
                              )}
                            </p>
                            <small>
                              {isInTestAB(userInfos, portfolioTest)
                                ? textLang.investorUpgradeDescrABTest[lang]
                                : textLang.investorUpgradeDescr[lang]}
                            </small>
                          </span>
                          <span className="d-flex justify-content-center mt-2 mt-sm-0">
                            <Button
                              variant="primary"
                              onClick={() => setShowModalPricing(true)}
                            >
                              {textLang.upgradeButtton[lang]}
                            </Button>
                          </span>
                        </div>
                      </Col>
                    ) : (
                      userInfos.pro_tier === secondProTier && (
                        <Col xs={12} className="py-2 border-bottom">
                          <div className="d-block d-sm-flex justify-content-between align-items-center">
                            <span className="text-start">
                              <p className="mb-0 text-dark">
                                {textLang.privateUpgrade[lang]}
                              </p>
                              <small>
                                {textLang.privateUpgradeDescr[lang]}
                              </small>
                            </span>
                            <span className="d-flex justify-content-center mt-2 mt-sm-0">
                              <Button
                                variant="falcon-success"
                                onClick={() =>
                                  !userInfos.trader
                                    ? setTraderModalShow(true)
                                    : userInfos.trader &&
                                      !isTraderCompleted(userInfos)
                                    ? routerHistory.push(
                                        '/account/trader-procedure'
                                      )
                                    : null
                                }
                                disabled={isTraderCompleted(userInfos)}
                              >
                                {!userInfos.trader ? (
                                  <p className="mb-0">
                                    {Account.becomeTrader[lang]}
                                  </p>
                                ) : userInfos.trader ? (
                                  !isTraderCompleted(userInfos) && (
                                    <p className="mb-0">
                                      {Account.becomeTrader[lang]}
                                    </p>
                                  )
                                ) : (
                                  userInfos.trader && (
                                    <p className="mb-0">Private</p>
                                  )
                                )}
                              </Button>
                            </span>
                          </div>
                        </Col>
                      )
                    )}
                    <Col xs={12} className="py-2 border-bottom">
                      <div className="d-block d-sm-flex justify-content-between align-items-center">
                        <span className="text-start">
                          <p className="mb-0 text-dark">
                            {textLang.delete[lang]}
                          </p>
                        </span>
                        <span className="d-flex justify-content-center mt-2 mt-sm-0">
                          <Button
                            variant="falcon-danger"
                            className="d-flex d-sm-block align-items-center"
                            onClick={() => manageSubscription('delete')}
                            disabled={disableSubsButton}
                          >
                            {textLang.deleteButtton[lang]}
                            <FontAwesomeIcon
                              className="ms-2"
                              icon="external-link-alt"
                            />
                          </Button>
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <p className="fs--1 mb-0">
                    <strong>{textLang.note[lang]}</strong>{' '}
                    {textLang.noteDescr[lang]}
                  </p>
                </>
              ) : (
                <Row>
                  <Col xs={12} className="py-2">
                    <div className="d-block d-sm-flex justify-content-between align-items-center">
                      <span className="text-start">
                        <p className="mb-0 text-dark">{textLang.noSub[lang]}</p>
                      </span>
                      <span className="d-flex justify-content-center mt-2 mt-sm-0">
                        <Button
                          variant="primary"
                          className="d-flex d-sm-block align-items-center"
                          onClick={() => setShowModalPricing(true)}
                        >
                          {textLang.noSubButton[lang]}
                        </Button>
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
              <hr />
              <Row>
                {(userInfos.pro_tier < secondProTier ||
                  (userInfos.pro_tier === traderProTier &&
                    !isTraderCompleted(userInfos))) && (
                  <Col xs={12} className="py-2">
                    <div className="d-block d-sm-flex justify-content-between align-items-center">
                      <span className="text-start">
                        <p className="mb-0 text-dark">
                          {textLang.privateUpgrade[lang]}
                        </p>
                        <small>{textLang.privateUpgradeDescr[lang]}</small>
                      </span>
                      <span className="d-flex justify-content-center mt-2 mt-sm-0">
                        <Button
                          variant="falcon-success"
                          onClick={() =>
                            !userInfos.trader
                              ? setTraderModalShow(true)
                              : userInfos.trader &&
                                !isTraderCompleted(userInfos)
                              ? routerHistory.push('/account/trader-procedure')
                              : null
                          }
                          disabled={isTraderCompleted(userInfos)}
                        >
                          {!userInfos.trader ? (
                            <p className="mb-0">{Account.becomeTrader[lang]}</p>
                          ) : userInfos.trader &&
                            !isTraderCompleted(userInfos) ? (
                            <p className="mb-0">{Account.becomeTrader[lang]}</p>
                          ) : (
                            userInfos.trader && <p className="mb-0">Private</p>
                          )}
                        </Button>
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

Subscription.propTypes = {
  getInfo: PropTypes.function
};

export default Subscription;
